@import '../../base.scss';

.main{
    @include main-column;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.box_text{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50%;
    width: 100%;
    padding-right: 30px;
}

.text{
    @include text;
}

.container{
    @include box-column;
    background-color: #353a3f;
    box-sizing: border-box;
    align-items: flex-start;
    max-width: 50%;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
}

.title{
    max-width: 480px;
    font-weight: 400;
    font-size: 38px;
    line-height: 1.2;
    text-align: left;
    color: $white;
    margin: 0 0 15px;
}

.title_info{
    @include title;
    text-align: left;
    margin-bottom: 20px;
}

.text{
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
    color: $white;
    margin: 0 0 10px;
}


.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.box_input{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 60px;
    background-color: $white;
    border-radius: 4px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, .25);
    border: 1px solid $title;

    &:nth-child(1){
        margin-top: 0;
    }
}

.label{
    box-sizing: border-box;
    width: 100%;
    color: $text-box;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 8px; 
}

.input{
    box-sizing: border-box;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: $text-box;
}

.wrong {
    color: $red;
}

.input_error{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $red;
    text-align: center;
}

.button_form{
    @include button;
}

.button_disabled{
    pointer-events: none;
    cursor: default;
}

.glare{
    @include glare
}

@media(max-width: 768px){
    .main{
        flex-direction: column;
    }

    .box_text{
        max-width: 100%;
        padding: 0;
        margin-bottom: 30px;
    } 

    .container{
        max-width: 80%;
    }

    .title{
        font-size: 30px;
    }
}

@media(max-width: 450px){

    .container{
        max-width: 100%;
    }
}