@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../image/Practical/img.jpg);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
    background-color: rgba(247, 242, 235, 0);
}


.list{
    max-width: 1440px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    padding: 5px;
}

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.item_box{
    width: 93px;
    height: 93px;
    border-radius: 50%;
    background: $element;
    background-size: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}

.item_image{
    width: 100%;
    aspect-ratio: 4/3;
    margin: 10px 0;
}

.item_title{
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
    margin: 0;
    padding: 20px 0;
    color: $white;
    text-align: center;
    background-color: $element;
}

.button{
    max-width: 100%;
    width: 100%;
    height: 60px;
    border: 0;
    color: white;
    background-color: $background-footer;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    position: relative;
    overflow-x: hidden;

    &:hover{
        cursor: pointer;
        background-color: $element;
        transform: scale( .95 );
        transition: all .5s ease-out;
        color: #1d2029;
    }
}

.glare{
    @include glare
}

@media(max-width: 1280px) {
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 1080px) {
    .item_title{
        font-size: 19px;
    }
}


@media(max-width: 768px){

    .type_box{
        padding: 0;
    }

    .type_title{
        font-size: 20px;
    } 
}

@media(max-width: 680px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }

    .item{
        background-color: $element;
        padding: 20px;
    }

    .item_title{
        font-size: 25px;
    }

    .item_image{
        margin: 0 0 10px 0;
    }
}

@media(max-width: 450px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 360px){
    .item_title{
        font-size: 18px;
    }
}