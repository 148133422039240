@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    @include box-column;
}

.container{
    @include box-column;
}

.title{
    @include title
}

.list{
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    padding: 0;
}

.button{
    max-width: 400px;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    margin: 20px 0 0;
    padding: 0;
    color: $element;
    border-radius: 16px;
    background: transparent;
    position: relative;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    border: 1px solid $element;
    transition: all .5s ease-out;

    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
        color: #1d2029;
        background: $element;
    }

    & svg {
        object-fit: cover;
        fill: $element;
        width: 20px;
        height: 20px;
        transition: all .5s ease-out;
    }

    &:hover svg {
        fill: #1d2029;
        transition: all .5s ease-out;
    }
}

@media(max-width: 450px){
    .button{
        max-width: 300px;
        height: 50px;
        font-size: 14px;
        line-height: 1.2;
        margin: 20px 0 0;
    
        & svg {
            object-fit: cover;
            fill: $element;
            width: 20px;
            height: 20px;
            transition: all .5s ease-out;
        }
    }
}

