@import '../../base.scss';

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
    transition: all .5s;
    cursor: pointer;

    &:hover span{
        transition: all .5s;
        opacity: 1;
    }
}

.box_image{
    width: 100%;
    aspect-ratio: 4/3;
    overflow: hidden;
    border-radius: 6px;
    position: relative;
}

.image{
    width: 100%;
    height: auto;
}

.box_text {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px 10px;
    background:  rgba(0, 0, 0, .33);
    backdrop-filter: blur(5px);
}

.text{
    box-sizing: border-box;
    width: 100%;
    color: $white;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
}

.icon {
    opacity: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
    background-color: $element;
    top: 49%;
    left: 49%;
    z-index: 3;
    transition: all .5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 450px){
    .box_text {
        padding: 10px 5px;
    }

    .text{
        font-size: 14px;
    }
}