@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    max-width: 1920px;
    box-sizing: border-box;
    width: 90%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.box_info{
    max-width: 700px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.title{
    @include title;
    text-align: left;
}

.text{
    @include text;
    text-align:justify;
}

.image{
    width: 600px;
    aspect-ratio: 1/1;
    border-radius: 6px;
}

@media(max-width: 1100px){
    .image{
        width: 45%;
    }
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 95%;   
        padding: 10px 0;
        gap: 20px;
    }

    .image{
        width: 100%;
    }
    
    .box_info{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }

}

@media(max-width: 450px){

    .container{
    padding: 10px 0;
    }
}