$white: #FFF;
$black: #252525;
$background-header:rgba(#252525, .4);
$background-footer:#292e32;
$background: #292e32;
$title: $white;
$red:#af0621; 
$text-box: #838383;
$element: #FFDD00;
$box:  #eff3f4;
$box-input:  #f2fafa;
$background-i: #353a3f;
$title-span: #FFDD00;

@keyframes flareAnimation {
    0% {
        left: -150%;
    }
    100% {
        left: 150%;
    }
}


@mixin main-column {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    width: 90%;
    padding: 50px 0;

    @media(max-width: 768px){
        padding: 20px 0;
        width: 95%;
    }

    @media(max-width: 450px){
        padding: 15px 0;
    }
}

@mixin box-column {
    box-sizing: border-box;
    max-width: 1920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin title {
    font-weight: 600;
    font-size: 38px;
    line-height: 1.2;
    text-align: center;
    color: $title;
    margin: 0;

    @media(max-width: 1020px){
        font-size: 32px;
    }

    @media(max-width: 768px){
            font-size: 26px;
        }

        & span {
            color: $element;
        }
}

@mixin text {
    color: $title;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0 0;

    @media(max-width: 1100px){
        font-size: 16px;
        line-height: 1.2;
}

    @media(max-width: 450px){
        font-size: 14px;
}
}

@mixin button{
    max-width: 300px;
    width: 100%;
    height: 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    border: none;
    text-transform: uppercase;
    margin: 20px 0 0;
    padding: 0;
    color: #1d2029;
    border-radius: 16px;
    background: $element;
    position: relative;
    overflow-x: hidden;

    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;

    }
}

@mixin glare{
    position: absolute;
    top: 0;
    height: 100%;
    width: 45px;
    transform: skewX(-45deg);
    animation: flareAnimation;
    left: -150%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5));
    animation: flareAnimation 3s infinite linear;
}

@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 5px;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 10px;
        }
}



