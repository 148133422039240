@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    max-width: 1920px;
    box-sizing: border-box;
    width: 90%;
    padding: 50px 0;
}

.box_info{
    box-sizing: border-box;
}


.title{
    @include title;
    text-align: left;
}

.text{
    @include text;
    text-align:justify;
}

.image{
    width: 500px;
    aspect-ratio: 1/1;
    border-radius: 6px;
    float:left;
    margin: 0 20px 0 0;
}

.box_button {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.button {
    @include button;
}

.glare{
    @include glare
}


@media(max-width: 1100px){
    .image{
        width: 45%;
    }
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;   
        padding: 40px 0;
    }

    .image{
        width: 100%;
        margin: 0 0 20px;
    }
    
    .box_info{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }

}

@media(max-width: 450px){

    .container{
    padding: 30px 0;
    }
}