@import '../../base.scss';

@keyframes pulse-1 {
    0%{
        transform: scale(1);
        opacity: .9;
    }
    100%{
        transform: scale(1.4);
        opacity: .3;
    }
}


.hint {
    box-sizing: border-box;
    background-color: #353a3f;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 5px rgba( $black, 0 );
    border-radius: 16px;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 5px;
}

.container{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.box {
    background-color: #353a3f;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    &:hover {
        
        & h3{
            color: $title-span;
        }
        cursor: pointer;
        transform: .3s all
    }
}

.hint_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 18px;
    line-height: 1.25;
    font-weight: 600;
    margin: 0;
    color: $white;
    text-align: left;
}

.text{
    @include text;
    width: 90%;
    margin: 10px 0;
    color: $text-box;
    transition: 1s;
    font-size: 18px;
}

.text_no{
    display: none;
    transition: 1s;
}


.hint_boxs{
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
}

.pulse{
    background: rgba($element, $alpha: .7);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-1 1s infinite;
    z-index: -1;
}

.hint_image_box{
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $element;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 1s ease;
    transform: rotate(0deg);
}

.hint_active{
    box-sizing: border-box;
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $element;
    transition: all 1s ease;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hint_image{
    width: 15px;
    height: auto;
    z-index: 10;
}

@media(max-width: 768px){
    .title {
        font-size: 16px;
    }

    .text{
        margin: 10px 0;
    }
}