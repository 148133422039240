@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
}

.title{
    @include title;
    margin-bottom: 30px;
}

.text{
    @include text;
    margin-bottom: 30px;
    text-align: center;
}

.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    padding: 0;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.button{
    @include button
}

@media(max-width: 1100px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}


@media(max-width: 450px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 10px;
    }
}