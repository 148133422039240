@import '../../base.scss';

@keyframes pulse-1 {
    0%{
        transform: scale(1);
        opacity: .9;
    }
    100%{
        transform: scale(1.4);
        opacity: .3;
    }
}

    .main {
        max-width: 100vw;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;
    }

    .overlay{
        max-width: 100%;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .43) 20.07%, rgba(0, 0, 0, 0.33) 28.86%);
    }

.container{
    box-sizing: border-box;
    max-width: 1920px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

.box {
    max-width: 100%;
    width: 100%;
    color: $white;
    background-image: url(../../image/InitialPage/img_2.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}

.title{
    max-width: 850px;
    font-size: clamp(36px, calc(36px + (60 - 36) * ((100vw - 768px) / (1920 - 768))), 60px);
    line-height: 1.2;
    font-weight: 700;
    margin: 0;
    position: relative;
    text-transform: uppercase;
}

.button{
    @include button;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 350px;
}

.glare{
    @include glare
}

//преимущества

.list {
    max-width: 700px;
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-top: 30px;
}

.item {
    display: flex;
    align-items: flex-start;
    max-width: 220px;
    gap: 10px;
}

.boxs{
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $element;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.pulse{
    background: rgba($element, $alpha: .9);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-1 1s infinite;
}

.check {
    color: #1d2029;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    z-index: 5;
}

.item_text {
    font-size: 14px;
    line-height: 1.2;
    color: $white;
    font-weight: 400;
    margin: 0;

    & span {
        font-weight: 600;
    }
}

////////////////////////////

@media(max-width: 1080px){
    .title{
        max-width: 700px;
    }

    .container{
        padding: 0;
        margin: 0 auto;
    }

}

@media(max-width: 768px){
    .main {
        justify-content: flex-start;
        align-items: center;
    }

    .title{
        font-size: clamp(36px, calc(36px + (60 - 36) * ((100vw - 768px) / (1920 - 768))), 60px);
        max-width: 600px;
    }

    .list {
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }

    .item {
        max-width: 100%;
        align-items: center;
        gap: 20px;
    }

    .item_text {
        font-size: 16px;
    }
}

@media(max-width: 500px){

    .title{
        font-size: clamp(30px, calc(30px + (60 - 30) * ((100vw - 768px) / (1920 - 768))), 60px);
    }
}

@media(max-width: 450px){
    .box {
        max-width: 100%;
        width: 100%;
        color: $white;
        background-image: url(../../image/InitialPage/img_4.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .boxs{
        min-width: 25px;
        height: 25px;
    }

    .item_text {
        font-size: 14px;
    }

    .button{
        max-width: 100%;
        height: 50px;
        margin-top: 20px;
    }
}